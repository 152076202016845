import {Injectable} from "@angular/core";
import {Http, Response, RequestOptionsArgs, Headers, RequestOptions} from "@angular/http";
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from './common.service';
import { HttpClient } from "@angular/common/http";


@Injectable(
    {
        providedIn: "root"
    }
)
export class ApiService {

    authService: any ={};
    private options : RequestOptions;
    private _baseUrl = "";

    private cache = {

        productCategories: []
    }

    constructor(private http : Http,
        private httpClient : HttpClient,
         private commonService: CommonService) {
        this._baseUrl = environment.apiUrl;
        this.options = new RequestOptions();
        var openIdTokenParams = JSON.parse(localStorage.getItem('openIdTokenParams'));
        if (openIdTokenParams && openIdTokenParams.access_token) {
            this.authService.accessToken = openIdTokenParams.access_token;
        }
    }


    private handleError(error : Response) {

        return Observable.throw(error.json());
    }



    getMemberReferences():Observable<any>{
        return this
            .http
            .get(this._baseUrl + "memberReference", this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }



    submitChannelRequest(channelRequest):Observable<any>{
        return this
            .http
            .post(this._baseUrl + `memberReference/submit`, channelRequest, this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    pulleFundsReport(appId, applicantIndex, isJoint):Observable<any>{
        return this
            .http
            .get(this._baseUrl + `xpressLoan/pull-eFunds-report/${appId}/${applicantIndex}/${isJoint}`, this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    pullCreditReport(appId):Observable<any>{
        return this
            .http
            .get(this._baseUrl + `xpressLoan/pull-credit-report/${appId}/`, this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    symitarBook(appId):Observable<any>{
        return this
            .http
            .post(this._baseUrl + `xpressLoan/symitar-book/${appId}/`, {}, this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    getVehiclePurposes(): Observable<any> {
        return this
        .http
        .get(this._baseUrl + `product/get-vehicle-purposes`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getAutoMakes(): Observable<any> {
        return this
        .http
        .get(this._baseUrl + `product/get-auto-makes`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }


      getServedAreas(): Observable<any> {
        return this
        .http
        .get(this._baseUrl + `employment/get-served-areas`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      
      getQualifyingZips(): Observable<any> {
        return this
        .http
        .get(this._baseUrl + `employment/get-qualifying-zips`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

            
      getEligibilityRelationships(): Observable<any> {
        return this
        .http
        .get(this._baseUrl + `employment/get-eligibility-relationship`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      //Docu-Sign

      getDocuSignDocs(appId: string, productGroupId, productId): Observable<any> {
        return this
        .http
        .get(this._baseUrl + `docuSign/generate-docu-sign/${appId}/${productGroupId}/${productId}`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getLoan(loanId): Observable<any> {
        return this
        .http
        .get(this._baseUrl + `xpressLoan/get-loan/${loanId}`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getLoanDecision(loanId): Observable<any> {
        return this
        .http
        .get(this._baseUrl + `xpressLoan/get-loan-decision/${loanId}`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getBank(routingNumber): Observable<any> {
        return this
        .http
        .get(`https://www.routingnumbers.info/api/data.json?rn=${routingNumber}`).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getNmaoConfigurations(): Observable<any> {
        return this
        .httpClient
        .get(this._baseUrl + `core/get-nmao-configurations`);
      }

      saveGate(configName, configJson): Observable<any> {
        return this
        .httpClient
        .post(this._baseUrl + `core/save-nmao-configuration`,{configName, configJson});
      }
     
}