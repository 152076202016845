import { Injectable } from "@angular/core";
import { Http, Response, RequestOptionsArgs, Headers, RequestOptions, ResponseContentType } from "@angular/http";
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    
    constructor(private http: Http,private commonService: CommonService) {

    }


    getToken(){


        var hash = CryptoJS.HmacSHA256("qWrY/SNwRk+yHdXAgCg+Og==", "lvpkjskdj2!skdjklgow@");
        var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);

        var headers = new Headers();
        headers.set('Content-Type', "application/x-www-form-urlencoded");
        let searchParams = new URLSearchParams();
        searchParams.append('client_id', "cf08d40d-faae-4b1f-8903-da0f498d6e9d");
        searchParams.append('client_secret', hashInBase64);
        searchParams.append('grant_type', "client_credentials");


        let requestBody = searchParams.toString();
        return this
        .http
        .post(this.commonService.baseUrl + "/connect/token",requestBody, {
            headers:headers
        }).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.commonService.handleError)
        )
    }


}