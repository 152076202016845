import { NgModule } from '@angular/core';
import { KeysPipe } from './pipes/keys.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon'
import { PhonePipe } from './pipes/phone.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { PlatformModule } from '@angular/cdk/platform';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SafePipe } from './pipes/safe.pipe';



// export const customConfig = {
//   align: "left",
//   allowNegative: false,
//   allowZero: true,
//   decimal: ".",
//   precision: 2,
//   prefix: "$ ",
//   suffix: "",
//   thousands: ",",
//   nullable: true,
//   min: null,
//   max: null,
//   inputMode: InputMode.NATURAL
// };

@NgModule({
  declarations: [KeysPipe, PhonePipe, SafePipe],
  imports: [
    CommonModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule, MatFormFieldModule, MatInputModule,
    MatSelectModule, MatAutocompleteModule,
    MatSlideToggleModule, MatButtonModule, MatIconModule,
    MatDialogModule, MatProgressSpinnerModule,
    MatRadioModule,
    PlatformModule,
    MatCardModule,
    MatExpansionModule,
    MatTooltipModule,
   // NgxCurrencyModule.forRoot(customConfig)
  ],
  exports: [
    CommonModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    KeysPipe, PhonePipe,SafePipe,
    MatDatepickerModule, MatFormFieldModule, MatInputModule, MatCheckboxModule,
    MatSelectModule, MatAutocompleteModule,
    MatSlideToggleModule, MatButtonModule, MatIconModule,
    MatDialogModule, MatProgressSpinnerModule,
    MatRadioModule,
    PlatformModule,
    MatCardModule,
    MatExpansionModule,
    MatTooltipModule
  ],
  providers: [

  ]
})
export class SharedModule { }
