
<div mat-dialog-title class="segment-title">Confirm</div>
<div mat-dialog-content class="segment-content mb-4">
    {{confirmMessage}}
</div>
<div class="row segment-action-btns" mat-dialog-actions style="padding: 0 !important;">
  <button mat-raised-button class="btn btn-custom btn-continue btn-block col" (click)="dialogRef.close(true)">Yes</button>
  <span class="col-md-1"></span>
  <button mat-stroked-button class="btn btn-custom btn-continue-primary col" (click)="dialogRef.close(false)">No</button>
</div>
