import { Component, HostListener, EventEmitter } from '@angular/core';
import { AuthService } from 'src/shared/auth.service';
import { CommonService } from 'src/shared/common.service';
import { Platform } from '@angular/cdk/platform';
import { PlatformLocation } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/components/confirmation-dialog/confirmation-dialog.component';
import { Router, NavigationStart } from '@angular/router';
import { ApplicantLoanComponent } from './applicant-loan/applicant-loan.component';
import { ApiService } from 'src/shared/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'so-client-app';
  public static isDesktop: Boolean;
  public static nmaoConfigs: any ={};
  MOBILE_WIDTH = 768;

  public static docuSignCallbacked: EventEmitter<any> = new EventEmitter<any>();

  public constructor(private authService: AuthService,
    location: PlatformLocation,
    private dialog: MatDialog,
    private router: Router,
    private apiService: ApiService,
    public commonService: CommonService, private pl: Platform) {
    this.initializeApp();
    location.onPopState(() => {

      // var r = confirm("You pressed a Back button! Are you sure?!");

      let dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true,
        data: {
          confirmMessage: "If you proceed then your form won't be saved and you will have to refill the form.",
        },
        panelClass: AppComponent.isDesktop ? 'col-md-6' : 'full-screen',
        autoFocus: false,
        hasBackdrop: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
                  // Call Back button programmatically as per user confirmation.
                  history.go(-2);
                  // Uncomment below line to redirect to the previous page instead.
                  // window.location.href = document.referrer // Note: IE11 is not supporting this.
        }else{
          history.pushState(null, null, window.location.pathname);
        }
        history.pushState(null, null, window.location.pathname);
      });
      
    });


    var _this = this;

    // window.addEventListener("beforeunload",async e=> {
    //   var confirmationMessage = "\o/";
    //   console.log("cond");


    //   let dialogRef = _this.dialog.open(ConfirmationDialog, {
    //     disableClose: true,
    //     data: {
    //       confirmMessage: "If you proceed then your form won't be saved and you will have to refill the form.",
    //     },
    //     panelClass: AppComponent.isDesktop ? 'col-md-6' : 'full-screen',
    //     autoFocus: false,
    //     hasBackdrop: true,
    //   });
    // var result =   await dialogRef.afterClosed().toPromise();
    //     // confirmationMessage
    //     // if (result) {
           
    //     // }else{
         
    //     // }

        
    //    e.returnValue = result;     // Gecko, Trident, Chrome 34+
    //   return true;              // Gecko, WebKit, Chrome <34

      

    // });

  }


  initializeApp() {
      // AppComponent.isDesktop = (!this.pl.isBrowser && (this.pl.ANDROID || this.pl.IOS)) ? false : true;
      AppComponent.isDesktop = (this.pl.isBrowser && window.innerWidth > this.MOBILE_WIDTH) ? true : false;
      // this.apiService.getNmaoConfigurations().subscribe(r=>{
      //   AppComponent.nmaoConfigs = r;
      // });

  }

}
