import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args:string[] = []) : any {
    let keys = [];
    for (var enumMember in value) {
      if (!isNaN(parseInt(enumMember, 10))) {
        keys.push({key: parseInt(enumMember), value: value[enumMember]});
        // Uncomment if you want log
        // console.log("enum key: ", enumMember);
        //  console.log("enum member: ", value[enumMember]);
      } 
    }
    if(args.indexOf('branchOfServices') > -1){
      keys = _.orderBy(keys,'value');
    }

    keys = _.sortBy(keys, function(item) {
      return item.value === 'OTHER' ? 1 : 0;
    });
    return keys;
  }
}