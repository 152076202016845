import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from 'src/components/error/error.component';

const routes: Routes = [
  {
    path: '', loadChildren: './applicant-loan/applicant-loan.module#ApplicantLoanModule',
  },
  {
    path: 'error-page', component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
