import { Component, Inject, OnInit } from '@angular/core';
import {MAT_SNACK_BAR_DATA,MatSnackBarRef} from '@angular/material/snack-bar';
@Component({
  selector: 'app-alert-action',
  templateUrl: './alert-action.component.html',
  styleUrls: ['./alert-action.component.css']
})
export class AlertActionComponent implements OnInit {
    message:string='';
    actions:string='';
   constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,private matRef:MatSnackBarRef<any>) {
          this.message = data.message;
          this.actions = data.actions;
    }
  ngOnInit(): void {
  }
  onClose(){
    this.matRef.dismiss();
  }
}
